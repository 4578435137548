@charset "UTF-8";
html {
  box-sizing: border-box;
  color: #666666;
  font-family: "Montserrat", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 18px;
  font-weight: 500; }

body {
  background-image: linear-gradient(105deg, #e5e5e5 60%, #ffffff 35%);
  min-height: 100vh; }

main {
  line-height: 1.5;
  max-width: 75%;
  padding-bottom: 40px;
  padding-left: 80px;
  padding-right: 80px; }

h1 {
  color: #333333;
  font-size: 48px;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 1em;
  padding-left: 80px;
  margin-top: 40px;
  text-transform: uppercase; }

h2 {
  color: #008fe8;
  font-size: 30px;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 1rem;
  text-transform: capitalize; }

p {
  margin-bottom: 1em;
  text-align: justify; }

main ul {
  padding-left: 2em;
  position: relative; }

main li {
  line-height: 1.5;
  margin-bottom: 1em;
  text-align: justify; }

main li:before {
  content: "⁖";
  left: 0;
  line-height: .75;
  font-size: 2em;
  position: absolute; }

a {
  color: #008fe8; }

.sr-only {
  height: 1px;
  left: -100vw;
  overflow: hidden;
  position: absolute;
  width: 1px;
  white-space: nowrap; }

.sr-only-focusable:focus, .sr-only-focusable:active {
  height: auto;
  left: 0;
  overflow: visible;
  position: relative;
  white-space: normal;
  width: auto;
  z-index: 999; }

.shadow {
  box-shadow: 6px 3px 18px 0px rgba(0, 0, 0, 0.3); }

strong {
  font-weight: 700; }

header {
  padding-top: 75px;
  width: 100%; }

#top-bar {
  align-items: center;
  display: flex;
  height: 75px;
  justify-content: space-between;
  padding-left: 80px;
  position: fixed;
  top: 0;
  transition: background-color .3s, box-shadow .3s;
  width: 100%;
  z-index: 10; }

.top-scrolled {
  background-color: #f1f1f1;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.1); }

.logo {
  height: 80%; }
  .logo img {
    height: 100%; }

.menu-icon {
  display: none;
  height: 40px;
  padding: 8px;
  width: 44px; }

nav {
  font-weight: 700;
  text-transform: uppercase; }
  nav a {
    color: #808080;
    display: inline-block;
    text-decoration: none; }
  nav > ul {
    display: flex;
    align-items: center;
    max-height: calc(100vh - 83px); }

.nav-item {
  height: 16px;
  padding-right: 1.5em; }
  .nav-item a, .nav-item h2 {
    font-size: 16px; }
  .nav-item:focus > a, .nav-item:focus > h2, .nav-item:focus-within > a, .nav-item:focus-within > h2, .nav-item:hover > a, .nav-item:hover > h2 {
    color: #008fe8;
    text-shadow: 1px 1px 1px #bbbbbb; }

.sub-menu {
  padding-right: 1.5em;
  position: relative; }
  .sub-menu h2 {
    color: #808080;
    display: inline-block;
    font-size: 16px;
    margin: 0;
    text-transform: uppercase; }
  .sub-menu svg {
    height: 16px;
    width: 16px;
    fill: #808080;
    vertical-align: top; }
  .sub-menu > ul {
    background-color: #dddddd;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    left: -200vw;
    position: absolute;
    top: 1em;
    width: 300px; }
  .sub-menu a {
    display: inline-block;
    font-size: 16px;
    padding: 12px 20px;
    transition: background-color .3s;
    width: 100%; }
  .sub-menu a:hover, .sub-menu a:focus {
    background-color: #cccccc; }
  .sub-menu:focus ul, .sub-menu:focus-within ul, .sub-menu.sub-expanded ul {
    left: 0; }

@media (pointer: fine) {
  .sub-menu:hover ul, .sub-menu ul:hover {
    left: 0; } }

body.home {
  background: url(../img/background-landscape.jpg) center center/cover; }
  body.home #top-bar {
    padding-left: 0; }
  body.home .nav-item > h2, body.home .nav-item > a {
    color: #d9d9d9; }
  body.home .nav-item:focus > a, body.home .nav-item:focus > h2, body.home .nav-item:focus-within > a, body.home .nav-item:focus-within > h2, body.home .nav-item:hover > a, body.home .nav-item:hover > h2 {
    color: #ddeeee;
    text-shadow: 0px 0px 2px #888888, 0px 0px 8px #b4ffe9; }
  body.home .logo {
    align-self: flex-start;
    height: auto;
    width: 25vw; }
  body.home .sub-menu svg {
    fill: #d9d9d9; }
  body.home .top-scrolled {
    background-color: transparent;
    box-shadow: none; }

.home-icons-outer {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-around;
  position: absolute;
  top: 0;
  width: 100%; }

.home-icons-inner {
  display: flex;
  justify-content: space-around;
  width: 50%; }

.home-icons-inner:first-child {
  padding-right: 50px; }

.home-icons-inner:last-child {
  padding-left: 50px; }

.home-icons-inner img {
  height: 8vw;
  margin: 8px;
  max-height: 100px; }

.home-slogan {
  left: 0;
  position: absolute;
  top: 70%;
  width: 100%; }

.home-slogan img {
  margin: 0 20%;
  width: 60%;
  transform: translate(0, -50%); }

.about-text {
  color: #188700;
  font-size: 22px;
  font-style: italic;
  font-weight: 700;
  line-height: 1.5;
  padding-right: 5%; }

.flex-center {
  align-items: flex-start;
  display: flex;
  justify-content: center;
  width: 100%; }

.team {
  text-align: center; }

img.team {
  height: 300px;
  margin-bottom: 1em;
  object-fit: cover;
  width: 246px; }

main.sternfels {
  display: flex;
  max-width: none; }
  main.sternfels div:first-child {
    max-width: 70%; }
  main.sternfels a {
    color: #000080; }

h2.sternfels {
  color: #000080;
  line-height: 1.5; }

img.sternfels {
  margin: 0 0 024px;
  width: 225px; }

p.clinical {
  font-weight: 700; }

img.clinical {
  width: 75%;
  margin-bottom: 2em; }

.collaborators {
  padding: 1em 0; }
  .collaborators li {
    color: #008fe8;
    font-size: 24px;
    line-height: 1; }
    .collaborators li:before {
      content: none; }

main.news {
  max-width: none; }
  main.news > p {
    max-width: 70%; }

#news-tabs {
  background: white;
  border-radius: 4px;
  overflow: hidden;
  width: 100%; }

.tab-list {
  display: flex;
  width: 100%; }
  .tab-list button {
    background: #808080;
    border: none;
    padding: 1em 1.5em;
    width: 50%; }
  .tab-list h2 {
    color: white;
    font-size: 24px;
    font-weight: 600;
    line-height: 1.5;
    margin: 0;
    text-align: left; }
  .tab-list .active {
    background: #008fe8; }

.tab-content {
  padding: 30px; }

#els-news h3 {
  color: #333333;
  font-weight: 700; }

#els-news a {
  font-weight: 600; }

#safety-news a:first-child h3 {
  margin-top: 0; }

#safety-news h3 {
  font-weight: 600;
  line-height: 1.8;
  margin: 1em 0;
  padding-left: 2em;
  position: relative; }
  #safety-news h3:before {
    content: "⁖";
    color: #333333;
    left: 0;
    line-height: .75;
    font-size: 2em;
    position: absolute; }

div.contact {
  padding-left: 2em;
  position: relative; }
  div.contact img {
    left: 0;
    position: absolute;
    top: 0; }

main.contact {
  display: flex;
  max-width: none; }
  main.contact > div:first-child {
    width: 60%; }
  main.contact > div:last-child {
    width: 40%; }
    main.contact > div:last-child img {
      position: relative;
      top: -96px;
      width: 100%; }

@media (max-aspect-ratio: 1 / 1) {
  .home-icons-outer {
    height: 80%; }
  .home-icons-inner {
    align-items: center;
    flex-direction: column;
    height: 50%; }
  .home-icons-inner img {
    height: 7vh; }
  .home-slogan {
    top: 69%; }
    .home-slogan img {
      margin: 0 10%;
      width: 80%; } }

@media (max-aspect-ratio: 7 / 9) {
  body.home {
    background: url(../img/background-portrait.jpg) center 60%/cover; }
  .home-icons-outer {
    height: 80%; }
  .home-icons-inner {
    align-items: center;
    flex-direction: column;
    height: 50%; }
  .home-icons-inner img {
    height: 7vh; }
  .home-slogan {
    top: calc(63% + 11vw); }
    .home-slogan img {
      margin: 0 10%;
      width: 80%; } }

@media (min-aspect-ratio: 2 / 1) {
  .home-slogan {
    top: 73%; } }

@media (min-aspect-ratio: 7 / 3) {
  .home-slogan {
    top: 75%; } }

@media (max-width: 1024px) {
  html {
    font-size: 16px; }
  main {
    padding-left: 60px;
    padding-right: 60px; }
  h1 {
    padding-left: 60px; }
  #top-bar {
    padding-left: 60px; }
  img.sternfels {
    width: 200px; } }

@media (max-width: 900px) {
  main {
    max-width: none;
    padding-left: 48px;
    padding-right: 48px; }
  h1 {
    margin: .5em 0;
    padding-left: 48px; }
  #top-bar {
    height: 64px;
    padding-left: 48px; }
  .menu-icon {
    display: block;
    margin-right: 40px; }
  nav {
    left: -200vw;
    padding: 0 48px;
    position: fixed;
    top: 64px;
    width: 100%; }
    nav > ul {
      background: white;
      border-top: 2px solid #008fe8;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
      display: block;
      left: -200vw;
      max-height: calc(100vh - 72px);
      opacity: 0;
      overflow-y: auto;
      padding: 48px;
      position: relative;
      top: 0;
      transition: opacity 0.3s, left 0s .3s;
      width: 100%; }
      nav > ul:focus, nav > ul:focus-within {
        left: 0;
        opacity: 1;
        transition: opacity 0.3s; }
    nav:focus, nav:focus-within, nav.mobile-nav-open {
      left: 0; }
      nav:focus > ul, nav:focus-within > ul, nav.mobile-nav-open > ul {
        left: 0;
        opacity: 1;
        transition: opacity 0.3s; }
  #nav-collapse .nav-item {
    height: auto; }
    #nav-collapse .nav-item a, #nav-collapse .nav-item h2 {
      color: #808080;
      border-bottom: 1px solid #f7f7f7;
      padding: 14px 20px;
      width: 100%; }
    #nav-collapse .nav-item a {
      margin-top: 8px; }
    #nav-collapse .nav-item:first-child a {
      margin-top: 0; }
    #nav-collapse .nav-item h2 {
      background-color: #f7f7f7; }
    #nav-collapse .nav-item:focus > a, #nav-collapse .nav-item:focus > h2, #nav-collapse .nav-item:focus-within > a, #nav-collapse .nav-item:focus-within > h2, #nav-collapse .nav-item:hover > a, #nav-collapse .nav-item:hover > h2 {
      color: #808080;
      text-shadow: none; }
    #nav-collapse .nav-item:focus > a, #nav-collapse .nav-item:focus-within > a, #nav-collapse .nav-item:hover > a {
      background-color: #cccccc; }
  #nav-collapse .sub-menu > ul {
    background: white;
    box-shadow: none;
    padding-left: 36px;
    position: static;
    width: 100%; }
  #nav-collapse .sub-menu svg {
    display: none; }
  body.home .logo {
    width: 30vw; }
  main.sternfels {
    flex-direction: column; }
    main.sternfels div:first-child {
      margin-bottom: 2em;
      max-width: none; }
  img.sternfels {
    width: 250px;
    margin: 0; }
  img.clinical {
    width: 85%; }
  .collaborators li {
    font-size: 18px; }
  main.news > p {
    max-width: none; }
  .tab-list h2 {
    font-size: 20px; }
  main.contact {
    flex-direction: column; }
    main.contact > div:first-child, main.contact > div:last-child {
      margin-bottom: 2em;
      width: 100%; }
    main.contact > div:last-child img {
      position: static;
      top: 0;
      width: 75%; } }

@media (max-width: 900px) and (orientation: landscape) {
  html {
    font-size: 13px; }
  body {
    -webkit-text-size-adjust: 100%; }
  h1 {
    font-size: 22px;
    margin: 0 0 .6em 0; }
  h2, .about-text {
    font-size: 18px;
    margin-bottom: .6em; }
  main li {
    margin-bottom: .25em; }
  p {
    margin-bottom: 0.25em; }
  #nav-collapse > ul {
    padding: 12px 48px;
    max-height: calc(100vh - 64px); }
  #nav-collapse .nav-item a, #nav-collapse .nav-item h2 {
    padding: 4px 20px;
    font-size: 13px; }
  body.home {
    background-position: center 60%; }
    body.home .logo {
      width: auto;
      height: 100%; } }

@media (max-width: 500px) {
  body {
    -webkit-text-size-adjust: 100%; }
  h1 {
    font-size: 7vw;
    margin: 0 0 .5em 0;
    padding-left: 5%; }
  h2 {
    font-size: 6vw; }
  #top-bar {
    padding-left: 5%; }
  .menu-icon {
    margin-right: 5%; }
  nav {
    padding: 0 8px; }
    nav > ul {
      padding: 16px; }
  .nav-item a, .nav-item h2 {
    font-size: 16px; }
  #nav-collapse .nav-item a, #nav-collapse .nav-item h2 {
    padding: 5px 20px; }
  main {
    padding-left: 5%;
    padding-right: 5%; }
    main li, main p {
      text-align: left; }
  body.home .logo {
    align-self: center;
    height: 100%;
    width: auto; }
  .about-text {
    font-size: 20px;
    line-height: 1.4; }
  img.sternfels {
    width: 200px; }
  img.clinical {
    width: 100%; }
  .tab-list h2 {
    font-size: 18px; } }
